import React from 'react';
import { Container, Paper, Button } from '@material-ui/core';

export default function Homepage(props) {
  return (
    <Container>
      <Paper style={{height: `calc(100vh - 4em)`, padding: "2em", margin: "2em"}} elevation={4}>
        <h1>Hello. This is nyvuu.</h1>
        <h2>You don't know who I am yet.</h2>
        <h3>Hopefully, you'll one day get to see the kinds of things I can create.</h3>
        <h3>This page will be updated with my projects and updates as time goes on, along with more about myself.</h3>
        <h3>I hope to see you soon.</h3>
      </Paper>
    </Container>
  );
}