import './App.css';
import React from 'react';
import Homepage from './components/Homepage';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from '@material-ui/core';

// eslint-disable-next-line
// const lightTheme = createMuiTheme({
//   palette: {
//     spacing: 8,
//     typography: {
//       fontFamily: ["Roboto", "'Seqou UI'", "Arial", "sans-serif"].join(","),
//     },
//     primary: {

//     },
//     secondary: {

//     },
//     background: {

//     },
//     text: {

//     },
//     action: {

//     },
//     divider: {

//     },
//     type: "light",
//   },

// });

// eslint-disable-next-line
const darkTheme = createMuiTheme({
  palette: {
    spacing: 8,
    typography: {
      fontFamily: ["Roboto", "'Seqou UI'", "Arial", "sans-serif"].join(","),
    },

    background: {
      default: "#303030",
      paper: "#424242",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    type: "dark"
  },
});

function App() {
  const theme = darkTheme;

  return (
    <div className="App" style={{display: "flex"}}>
      <ThemeProvider theme={theme}>
        <CssBaseline/> 
        <Homepage/>
      </ThemeProvider>
    </div>
  );
}

export default App;
